import React, { useEffect, useState, useRef } from 'react';
import CoinbaseModal from './CoinbaseModal'

import {
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components'
import {
  displayErrors, isEmail, updateFormField, 
  validateAndPostSubscriptionElements,
  processCoinbasePayment
} from '../client/subscribe/actions'

const CardLabel = styled.div`
  margin-bottom: 0px;
  font-variant: all-small-caps;
`
const Button = styled.button`
  padding: 1rem 2rem 1rem 3rem;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: #0052FF;
  background-image: url("/img/crypto-icon.svg"); 
  background-repeat: no-repeat;
  background-position: 1.5rem center;
  margin-top: 5px;
`

function QuickPay({
  priceNum,
  currency,
  stripe,
  group,
  channel,
  period,
  textColor,
  offerName,
  coinbaseEnabled,
  coinbaseCharge,
  modalStyles,
  styles,
  openModal
}) {

  // Crypto 
  const handleCryptoPayment = () => {
    store.dispatch(validateAndPostSubscriptionElements({
      provider: 'coinbase'
    }))
  }

  const processCryptoPayment = (data) => {
    store.dispatch(processCoinbasePayment(
      data, coinbaseCharge
    ))
  }

  // console.log('QuickPay got price num', priceNum)
  const handlePaymentClick = async (event) => {
    const errors = []
    const { form, page, coupon } = store.getState()

    let afterCouponPrice = priceNum
    if (coupon.valid && coupon.couponSubmitted) {
      const discountAmount = coupon.amount_off
        ? (coupon.amount_off / 100)
        : (priceNum * (coupon.percent_off / 100))
      afterCouponPrice = priceNum - discountAmount
    }
    if (pr) pr.update({
      total: {
        label,
        amount: (!page.trialPeriod || !parseInt(page.trialPeriod)) ? Number((afterCouponPrice * 100).toFixed(0)) : 0,
      },
    })
    store.dispatch(updateFormField('paymentRequest', pr))

    if (page.customTOS && (form.tosAccepted === undefined || form.tosAccepted === false)) {
      errors.push({ field: 'customTOS', message: 'Please accept our terms of service.' })
    }

    if (errors.length > 0) {
      event.preventDefault();
      console.log('found errors', errors)
      store.dispatch(displayErrors(errors))
      return false;
    }
    return true;
  }
  const onPaymentMethodReceived = async (ev) => {
    // console.log('payment method recieved', ev)
    store.dispatch(updateFormField('paymentMethod', ev))
    store.dispatch(updateFormField('paymentMethodId', ev.paymentMethod.id))
    store.dispatch(updateFormField('email', ev.payerEmail))
    store.dispatch(updateFormField('emailConfirm', ev.payerEmail.id))

    store.dispatch(validateAndPostSubscriptionElements({
      provider: 'stripe'
    }))
  }

  const [pr, setPr] = useState(null);
  const label = `${offerName || channel} ${period || 'monthly'}`;
  // console.log('got country', state.page.country)
  useEffect(() => {
    const state = store.getState()
    if (stripe) {
      const _pr = stripe.paymentRequest({
        country: state.page.country || 'US',
        currency,
        total: {
          label,
          amount: Number((priceNum * 100).toFixed(0)),
        },
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      _pr.canMakePayment().then((result) => {
        if (result) {
          // console.log('payment request created')
          setPr(_pr)
          _pr.on('paymentmethod', onPaymentMethodReceived)
        } else {
          console.log('cant use PaymentRequest', result);
        }
      });
    }
  }, [stripe]);

  if (pr || coinbaseEnabled) {
    return (
      <div>
        <CardLabel style={{ textAlign: 'center', color: textColor, marginTop: '5px' }}>
          or pay with
        </CardLabel>
        <div style={{ margin: 'auto', marginTop: '5px', width: '45%' }}>
          {pr && <PaymentRequestButtonElement
            onClick={handlePaymentClick}
            options={{ paymentRequest: pr }}
          />}          
          {coinbaseEnabled && <Button
            type="button"
            onClick={handleCryptoPayment}
          >Crypto</Button>}
        </div>

        {/* Coinbase Modals */}
        {openModal 
          && (openModal.modalType === 'coinbase' || openModal.modalType === 'coinbase-close')
          && <CoinbaseModal
          styles={styles}
          modalStyles={modalStyles}
          openModal={openModal}
          charge={openModal.modalProps}
          handlePayment={processCryptoPayment}
        />}
      </div>
    );
  }

  // Use a traditional checkout form.
  return null;
}

export default QuickPay;