// CardSection.js
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

class CardSection extends React.Component {
  render() {
    const { preview } = this.props;

    const style = {
      base: {
        color: '#333',
        fontSize: '18px',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#333',
        },
      },
      invalid: {
        color: '#d20101',
        ':focus': {
          color: '#333',
        },
      },
    };
    const status = this.props.status;

    return (!preview && (status !== 'Disabled')) ? (
      <span style={status === 'Disabled' ? { cursor: 'not-allowed' } : {}}>
        <div
          className={
            status === 'Disabled'
              ? `${this.props.styles.elementsDisabledContainer}`
              : `${this.props.styles.elementsContainer}`
          }
        >
          <CardElement onBlur={this.props.onBlur} style={style} onChange={this.props.handleCreditCardUpdate} />
        </div>
      </span>
    ) : (
      <div
        className={this.props.styles.elementsDisabledContainer}
        style={{
          padding: "5px 0",
          backgroundColor: "#fff",
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          opacity: status === 'Disabled' ? 0.3 : 1
        }}
      >
        <div>
          <img style={{ width: "100%", maxHeight: 55 }} src="/img/stripe-placeholder-cardnum.png" /> 
        </div>
        <div>
          <img style={{ width: "100%", maxHeight: 55 }} src="/img/stripe-placeholder-mmyy-cvc.png" /> 
        </div>
      </div>
    );
  }
}

export default CardSection;
