import React from 'react'
import classNames from 'classnames'
import ReactPlayer from 'react-player'

const AboutMediaEmbed = ({ styles, url }) => {
  return (
    <div style={{ paddingTop: 30 }}>
      <ReactPlayer width='100%' height='460px' url={url} controls />
    </div>
  )
}

export default AboutMediaEmbed
