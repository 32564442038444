/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
// CheckoutForm.js
import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import Spinner from '../clientAdmin/components/Spinner'

import CustomTOS from './CustomTOS'

import {
  validateAndPostSubscriptionElements,
  toggleCouponField,
  validateCoupon,
  updateFormField,
  clearError
} from '../client/subscribe/actions'
import QuickPay from './QuickPay';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { creditCardValid: false }
  }

  handleTOSClick = (event) => {
    store.dispatch(updateFormField('tosAccepted', event.target.checked))
  }

  // NOTE: In future used controlled compoenent, DOM access is antipattern
  handleCouponClick = (event) => {
    // console.log('handleCouponClick');
    event.preventDefault()
    const coupon = document.getElementById('couponField').value
    // console.log('found coupon', coupon);
    store.dispatch(validateCoupon(coupon))
  }

  displayCouponField = () => {
    console.log('displayCouponField');
    store.dispatch(toggleCouponField(true))
  }

  handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      const coupon = event.target.value
      store.dispatch(validateCoupon(coupon))
      event.preventDefault()
    }

    return false
  }

  handleCardBlur = (ev) => {
    // console.log("handle Blur");
    const cardElement = this.props.elements.getElement('card');
    store.dispatch(updateFormField('card', cardElement))
    // console.log("cardElement", cardElement);
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (typeof clicky !== 'undefined') {
      clicky.goal('paidInviteSignup')
    }
    if (window.lp_event) window.lp_event('initiate_checkout')
    store.dispatch(validateAndPostSubscriptionElements({
      provider: 'stripe'
    }))
  }


  handleCreditCardUpdate = (elementType) => {
    if (
      !elementType.empty
      && !elementType.error
      && elementType.complete
    ) {
      this.setState({ creditCardValid: true })
    } else {
      this.setState({ creditCardValid: false })
    }
  }

  render() {
    const currency = this.props.currency.toUpperCase()
    const { formattedPrice } = this.props

    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          className={this.props.styles.signupForm}
        >
          <input
            disabled={this.props.status === 'Disabled' ? 'disabled' : false}
            className={[(this.props.formErrors.email || ''), this.props.inputStyles, this.props.styles.email].join(' ')}
            name="email"
            value={this.props.email}
            placeholder="Email"
            onChange={(e) => { 
              store.dispatch(clearError('email')) 
              store.dispatch(updateFormField('email', e.target.value)) 
            }}
          />
          <input
            disabled={this.props.status === 'Disabled' ? 'disabled' : false}
            className={[(this.props.formErrors.emailConfirm || ''), this.props.inputStyles, this.props.styles.email, this.props.styles.emailConfirm].join(' ')}
            name="emailConfirm"
            value={this.props.email}
            placeholder="Confirm email"
            onChange={(e) => { 
              store.dispatch(clearError('emailConfirm'))
              store.dispatch(updateFormField('emailConfirm', e.target.value)) 
            }}
            style={{ marginTop: 5 }}
          />

          {this.props.requireNameEnabled
          && (
          <input
            className={[(this.props.formErrors.nameOnCard || ''), this.props.inputStyles, this.props.styles.name].join(' ')}
            name="nameOnCard"
            placeholder="Name on card"
            value={this.props.nameOnCard}
            onChange={(e) => { 
              store.dispatch(clearError('nameOnCard'))
              store.dispatch(updateFormField('nameOnCard', e.target.value)) 
            }}
          />
            )}
          <CardSection
            preview={this.props.preview}
            status={this.props.status}
            onBlur={this.handleCardBlur}
            styles={this.props.styles}
            handleCreditCardUpdate={this.handleCreditCardUpdate}
          />
          { this.props.period === 'monthly'
          && (
          <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${formattedPrice} ${currency} per month (${this.props.couponInfo})`}
          </div>
          )}
          { this.props.period === 'yearly'
          && (
          <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${formattedPrice} ${currency} per year (${this.props.couponInfo})`}
          </div>
          )}
          { this.props.period === 'semiannual'
          && (
          <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${formattedPrice} ${currency} semiannually (6-months) (${this.props.couponInfo})`}
          </div>
          )}
          { this.props.period === 'quarterly'
          && (
          <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${formattedPrice} ${currency} quarterly (3-months) (${this.props.couponInfo})`}
          </div>
          )}
          { this.props.period === 'weekly'
          && (
          <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${formattedPrice} ${currency} per week (${this.props.couponInfo})`}
          </div>
          )}
          { this.props.period === 'daily'
          && (
          <div className={this.props.styles.priceDetail} style={{ color: this.props.textColor }}>
            {`${formattedPrice} ${currency} per day (${this.props.couponInfo})`}
          </div>
          )}
          {this.props.customTOS
          && <CustomTOS customTOS={this.props.customTOS} formErrors={this.props.formErrors} textColor={this.props.textColor} />}

          {!this.props.subscribing
          && (
          <button
            type="submit"
            disabled={this.props.status === 'Disabled' || !this.state.creditCardValid}
            className={this.props.payBtnStyles}
            style={{ background: this.props.buttonColor || '#469CCE', color: this.props.status === 'Disabled' || !this.state.creditCardValid ? 'lightgrey' : '#FFF' }}
          >
            {this.props.btnContent}
          </button>
          )}
          
          {!this.props.subscribing && (
            <QuickPay {...this.props} completePayment={this.completePayment} />
          )}

        </form>
        
        { this.props.subscribing
          && (
          <div>
            <Spinner color="#FFFFFF" size={40} />
          </div>
          )}
        { (this.props.period !== 'one-time' && !this.props.couponsEnabled)
          && (
          <div className={this.props.styles.unsubscribeText} style={{ color: this.props.textColor }}>
            No risk, cancel anytime
          </div>
          )}
        { (this.props.couponsEnabled && !this.props.couponFieldVisible)
          && (
          <div className={this.props.styles.unsubscribeText} style={{ color: this.props.textColor }}>
            <span tabIndex="-1" name="displayCoupon" role="button" onKeyUp={this.displayCouponField} onClick={this.displayCouponField} style={{ color: this.props.textColor || 'white', cursor: 'pointer', textDecorationLine: 'underline' }}> Have a coupon? Click to apply it.</span>
          </div>
          )}
        { (this.props.couponsEnabled && this.props.couponFieldVisible)
          && (
          <div>
            {!this.props.couponValid
              && (
              <form
                className={this.props.styles.couponForm}
                style={{ border: `2px solid ${this.props.couponSubmitted ? 'red' : 'white'}` }}
              >
                <input
                  id="couponField"
                  className={this.props.styles.couponInputField}
                  type="text"
                  placeholder="enter coupon..."
                  defaultValue={this.props.coupon}
                  onKeyPress={this.handleKeyPress}
                  onFocus={(e) => { e.target.placeholder = '' }}
                  onBlur={(e) => { e.target.placeholder = 'enter coupon...' }}
                />
                <button
                  className={this.props.styles.couponButton}
                  name="couponButton"
                  style={{ borderColor: this.props.buttonColor, background: this.props.buttonColor }}
                  onClick={this.handleCouponClick}
                >
                  { !this.props.couponValidating && <span>apply</span> }
                  { this.props.couponValidating && <Spinner color="#FFFFFF" size={30} /> }
                </button>
              </form>
              )}

            <div className="couponMessage" style={{ marginBottom: '10px', fontSize: '12px', color: this.props.textColor }}>
              {(this.props.couponValid && this.props.couponSubmitted)
                && <p name="couponSuccess">Coupon successfully applied!</p>}
              {(!this.props.couponValid && this.props.couponSubmitted)
                && <p name="couponInvalid">Invalid coupon. Please try again.</p>}
            </div>
          </div>
          )}

      </div>

    );
  }
}

function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
}

export default InjectedCheckoutForm;
