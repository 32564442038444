import React from 'react'
import { 
    updateFormField,
} from '../client/subscribe/actions'
class CustomTOS extends React.Component {
    handleTOSClick = (event) => {
      store.dispatch(updateFormField('tosAccepted', event.target.checked))
    }
  
    render() {
        return (
            <div>
            <div className={"checkbox"} >
                <input
                id="customTOS" 
                type="checkbox"
                name="customTOS"
                style={{
                transform: 'scale(1.5)',
                marginRight: "10px"
                }}
                onClick={this.handleTOSClick}
                />
                <label style={{color: this.props.textColor}} htmlFor='tos'>
                I have read and agree to the <a href={this.props.customTOS} style={{color: this.props.textColor}} target="_blank">terms &amp; conditions</a>.
                </label>
            </div>
        
            {this.props.formErrors.customTOS && 
            <p style={{ marginTop:'10px', marginBottom: '0px', visibility: 'visible',  color: this.props.textColor}}>You must accept the Terms &amp; Conditions</p>
            }
            </div>
    )}
  }
  export default CustomTOS
