import React from 'react'
import classNames from 'classnames'
import WelcomeModal from './WelcomeModal'

import ErrorModal from './ErrorModal'
import Spinner from '../clientAdmin/components/Spinner'

import { validateAndPostEmail, clearError } from '../client/subscribe/actions'

const submitForm = (field) => (event) => {
  event.preventDefault()
  if (typeof clicky !== 'undefined') {
    clicky.goal('freeInviteSignup')
  }

  if (window.lp_event)
    lp_event('freeInviteSignup')

  const form = event.target.form
  if (form.email) {
    store.dispatch(validateAndPostEmail({
      email: form.email.value,
    }))
  }
  return false
}

const EmailForm = ({
  styles,
  errors,
  openModal,
  modalStyles,
  group,
  subscribing,
  textColor,
  buttonColor,
  backgroundColor,
  convertCode,
  email,
  platform,
  emailState,
}) => {
  const [emailValue, setEmailValue] = React.useState(email || '')

  const emailError = (emailState && errors && errors.length && errors[0].field === 'email') ? styles.fieldError : ''
 
  return (
    <div>
      { (openModal && (openModal.modalType === 'success'))
        && <WelcomeModal
          styles={modalStyles}
          group={group} 
          platform={platform}
          />
      }
      { (openModal && (openModal.modalType === 'error'))
        && <ErrorModal
          styles={modalStyles}
          group={group}
          error={errors} />
      }
      <div style={{ color: textColor }} className={styles.freeHeader}>FREE to join</div>
      <form
        onSubmit={submitForm}
        className={styles.signupForm}>
        <input
          className={classNames(emailError, styles.formInput, styles.email)}
          name="email"
          placeholder="Email"
          value={emailValue}
          onChange={(e) => {
            setEmailValue(e.target.value)
            store.dispatch(clearError('email'))
          }}
          onSubmit={submitForm}
          />
        { !subscribing
          && <button
            style={{ background: buttonColor || '#182b3d', color: '#FFF',width:'50%' }}
            type="submit"
            className={styles.submitButton}
            onClick={submitForm(false)}>
            Join Now
          </button>
        }
        { subscribing
          && <Spinner color={'#FFFFFF'} size={40} />
        }
      </form>
    </div>
  )
}

export default EmailForm
