import numbro from 'numbro'

import React from 'react'
import ReactDOM from 'react-dom'
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from 'config'
import * as reducers from './reducers'
import StripeFormElements from '../components/StripeFormElements'

import EmailForm from '../components/EmailForm'
import AboutMediaEmbed from '../components/AboutMediaEmbed'

import 'url-search-params-polyfill'

const BugsnagSettings = config.bugsnagSettings;
BugsnagSettings.releaseStage = config.environment;
BugsnagSettings.appType = 'client'
BugsnagSettings.appVersion = config.version

BugsnagSettings.plugins = [new BugsnagPluginReact()]
Bugsnag.start(BugsnagSettings)
console.log(`launchpass v${config.version} client ${config.environment}`);

const setInitialState = (state) => {
  // console.log('initialState', JSON.stringify(state, null, 2));
  if (state.selector) {
    if (state.selector.price) {
      let price = state.selector.price.toString()
      price = price.indexOf('$') === 0 ? price : numbro(price).divide(100).formatCurrency('$0,0.00')
      state.selector.price = price
      state.selector.currentPrice = price
    }
  }

  // TODO add polyfill for document.location.search and URLSearchParams
  const params = new URLSearchParams(document.location.search);
  const type = params.get('s');
  const sessionId = params.get('session_id');
  if (type == '1' && sessionId) {
    state.form.subscribing = true;
    state.form.session_id = sessionId;
  }
  state.stripe = null;
  state.enableBeta = params.get('enableBeta')
  return state
}

const store = window.store = createStore(
  combineReducers(reducers),
  setInitialState(initialState),
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
)

const renderStripeForm = () => {
  const state = store.getState()
  const embedmatch = '/embed';
  const embedmatches = document.location.pathname.substr(-embedmatch.length, embedmatch.length) === embedmatch;
  
  if (state.page.period === 'free') {
    ReactDOM.render(
      <EmailForm
        errors={state.form.errors}
        openModal={state.openModal}
        styles={styles.EmailForm}
        modalStyles={styles}
        group={state.page.group}
        subscribing={state.form.subscribing}
        backgroundColor={state.page.backgroundColor}
        buttonColor={state.page.buttonColor}
        textColor={state.page.textColor}
        email={state.params.email}
        emailState={state.form.email}
        coupon={state.params.coupon}
        platform={state.page.platform}
      />,
      document.getElementById('free-form'),
    )
    if (state.page.mediaEmbed && !embedmatches) {
      ReactDOM.render(
        <AboutMediaEmbed
          url={state.page.mediaEmbed}
        />,
        document.getElementById('media-embed'),
      )
    }
  } else {
    ReactDOM.render(
      <StripeFormElements
        enableBeta={state.enableBeta}
        url={state.page.url}
        channel={state.page.channel}
        group={state.page.group}
        price={state.selector.price}
        currency={state.selector.currency}
        priceYearly={state.selector.priceYearly}
        yearlySelected={state.selector.yearlySelected}
        currentPrice={state.selector.currentPrice}
        errors={state.form.errors}
        subscribing={state.form.subscribing}
        period={state.page.period}
        trialPeriod={state.page.trialPeriod}
        openModal={state.openModal}
        styles={styles.StripeForm}
        modalStyles={styles}
        backgroundColor={state.page.backgroundColor}
        buttonColor={state.page.buttonColor}
        textColor={state.page.textColor}
        status={state.page.status}
        alternatePayment={state.page.alternatePayment}
        couponsEnabled={state.coupon.couponsEnabled}
        verifyEmailEnabled={state.page.verifyEmailEnabled}
        useOauthEnabled={state.page.useOauthEnabled}
        requireNameEnabled={state.page.requireNameEnabled}
        requireAddressEnabled={state.page.requireAddressEnabled}
        customSetting1Enabled={state.page.customSetting1Enabled}
        customSetting2Enabled={state.page.customSetting2Enabled}
        customSetting3Enabled={state.page.customSetting3Enabled}
        couponFieldVisible={state.coupon.couponFieldVisible}
        couponValid={state.coupon.valid} // vs couponValid
        couponId={state.coupon.id}
        couponAmountOff={state.coupon.amount_off}
        couponPercentOff={state.coupon.percent_off}
        couponDurationInMonths={state.coupon.duration_in_months}
        couponCurrency={state.coupon.currency}
        couponDuration={state.coupon.duration}
        couponSubmitted={state.coupon.couponSubmitted}
        couponValidating={state.coupon.couponValidating}
        customTOS={state.page.customTOS}
        email={state.params.email}
        coupon={state.params.coupon}
        stripe={state.stripe}
        platform={state.page.platform}
        coinbaseEnabled={state.page.coinbaseEnabled}
        coinbaseCharge={state.coinbase.charge}
      />,
      document.getElementById('stripe-form'),
    )
    if (state.page.mediaEmbed && !embedmatches) {
      ReactDOM.render(
        <AboutMediaEmbed
          url={state.page.mediaEmbed}
        />,
        document.getElementById('media-embed'),
      )
    }
    
  }
}

store.subscribe(renderStripeForm)
renderStripeForm()
